import React from 'react'

const PageWrapper = ({ children, className }) => (
  <div
    className={className}
    style={{
      background: '#fff',
      borderTop: '3px solid #9c698d',
    }}
  >
    {children}
  </div>
)
export default PageWrapper

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import RecentArticles from '../components/recent-articles'
import PageWrapper from '../components/pageWrapper'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulPost.edges')

    return (
      <Layout>
        <PageWrapper>
          <Helmet title={siteTitle} />
          <div className="wrapper p-3">
            <h2 className="section-headline font-weight-bold">Videos</h2>
            <RecentArticles articles={posts} />
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          title
          slug
          intro
          linkToPostLabel
          subtitle
          createdAt(formatString: "DD | MM | YYYY")
        }
      }
    }
  }
`

import React from 'react'
import ArticlePreview from './article-preview'
import { ListGroup } from 'react-bootstrap'
const RecentArticles = ({ articles }) => {
  return (
    <ListGroup variant="flush" className="d-flex flex-column">
      {articles.map(({ node }) => {
        return (
          <ListGroup.Item key={node.slug}>
            <ArticlePreview article={node} />
          </ListGroup.Item>
        )
        return
      })}
    </ListGroup>
  )
}

export default RecentArticles

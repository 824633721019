import React from 'react'
import { Link } from 'gatsby'

export default ({ article }) => (
  <div style={{ borderLeft: '4px solid #9c698d', paddingLeft: '1rem' }}>
    <p className="mb-2 text-muted"> {article.createdAt}</p>
    <p className="mb-2 text-muted font-weight-bold"> {article.subtitle}</p>
    <h3 className="font-weight-bold">{article.title}</h3>

    <p className="font-weight-bold">
      {article.intro}{' '}
      <Link to={`/post/${article.slug}`}>{article.linkToPostLabel}</Link>
    </p>
  </div>
)
